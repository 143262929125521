import meta from './meta';

export default {
  ...meta,
  'direction.courses': 'Todos',
  'direction.coursesMobile': 'Todas as áreas',
  'direction.0.metaDescription':
    'Cursos online com certificados válidos em todo Brasil. Os professores de melhores práticas irão ensinar-lhe todos os segredos das profissões digitais.',
  'direction.0.metaTitle': 'Cursos online com Certificado — EBAC Online',
  'direction.0.seoText':
    'Cursos online com certificados válidos em todo Brasil. Os professores de melhores práticas irão ensinar-lhe todos os segredos das profissões digitais.',
  'direction.0.title': 'Cursos online',
  'types.any': 'Todos',
  'types.course': 'Curso',
  'types.profession': 'Profissão',
  month: 'sem meses | {count} mês | {count} meses',
  course: 'cursos | curso | cursos',
  profession: 'profissões | profissão | profissões',
  day: '0 dias | 1 dia | {days} dias',
  and: 'e',
  required: 'Preenchimento obrigatório',
  min: 'Comprimento de campo incorreto',
  email: 'Endereço de email invalido',
  comingSoon: 'Em breve',
  currencySign: 'R$',
  less: 'menos',
  more: 'mais',
  loading: 'Carregando...',

  // template
  'template.login': 'Sou Aluno',
  'template.contract': 'Contrato de prestação',
  'template.serviceAgreementAndPrivacyPolicy':
    'Política de Privacidade e Contrato de Prestação de Serviço',
  'template.privacyPolicy': 'Politica de privacidade',
  'template.phone': 'Telefone',
  'template.email': 'E-mail',
  'template.aboutUs': 'A EBAC',
  'template.careersCenter': 'Centro de carreiras',
  'template.jobs': 'Trabalhe conosco',
  'template.b2b': 'Para empresas',
  'template.design': 'Design',
  'template.audioVisual': 'Audiovisual',
  'template.marketing': 'Marketing',
  'template.scheduleAndDate': 'Programação & Data',
  'template.games': 'Games',
  'template.linkToMx': 'Cursos on-line no México',

  // additionalNavigation component
  'additionalNavigation.careerCenterStudents': 'Para alunos',
  'additionalNavigation.careerCenterCompany': 'Para empresas',

  // firstSection component
  'firstSection.aboutUs': 'Sobre',
  'firstSection.blog': 'Blog',

  // mainNavigation component
  'mainNavigation.aboutUs': 'Sobre nós',
  'mainNavigation.careerCenter': 'Centro de carreiras',
  'mainNavigation.b2b': 'Para empresas',
  'mainNavigation.jobs': 'Trabalhe conosco',
  'mainNavigation.ourStudents': 'Nossos estudantes',
  'mainNavigation.massMedia': 'EBAC na mídia',

  // phone component
  'phone.error': 'Digite seu número de telefone',
  'phone.other': 'Outro',
  'phone.placeholder': 'Telefone',

  // reviews component
  'reviews.h2': 'Avaliação dos alunos',

  // plan component
  'plan.h2': 'A rotina dos cursos',
  'plan.1.h3': 'Aprenda sobre o tema',
  'plan.1.description':
    'Cursos compostos por videoaulas detalhadas e de fácil entendimento',
  'plan.2.h3': 'Coloque o aprendizado em prática',
  'plan.2.description':
    'Atividades para praticar no ritmo mais apropriado para você',
  'plan.3.h3': 'Troque&nbsp;conhecimento com o tutor',
  'plan.3.description':
    'Correção individual dos trabalhos e feedback para melhorias',
  'plan.4.h3': 'Apresente um trabalho autoral',
  'plan.4.description': 'Excelente oportunidade para enriquecer seu portfólio',

  // aboutUs component
  'about-us.description':
    'A Escola Britânica de Artes Criativas e Tecnologia (EBAC) é uma instituição inovadora de ensino superior em Artes Criativas e Tecnologia que oferece cursos online, além de programas presenciais e híbridos de graduação, especialização e iniciação.',

  'about-us.1.title': '+150',
  'about-us.1.description':
    'cursos livres e ensino superior nas áreas de TI, design, marketing, audiovisual, fashion e negócios',

  'about-us.2.title': '145 mil',
  'about-us.2.description': 'alunos estudando com nossos cursos online',

  'about-us.3.title': '100%',
  'about-us.3.description':
    'dos professores e coordenadores são profissionais atuantes no mercado',

  'about-us.4.title': '+40',
  'about-us.4.description':
    'empresas parceiras colaboram no desenvolvimento dos cursos, projetos reais e programas de estágio',

  // promoCode component
  'promoCode.exception':
    'Aconteceu um erro, entre em contato com info@ebaconline.com.br',
  'promoCode.couponNotFound': 'O cupom não foi encontrado',
  'promoCode.couponUsed': 'O limite de uso deste cupom acabou',
  'promoCode.couponCannotBeUsedForProduct':
    'Este cupom não é válido para o curso "{name}"',
  'promoCode.couponIsNotActive': 'Este cupom não está ativo',
  'promoCode.couponDidNotStart':
    'Este cupom não é válido, pois seu prazo de uso tem início em {date}',
  'promoCode.couponExpired':
    'Este cupom não pode ser usado porque sua validade expirou em {date}',
  'promoCode.smallDiscount':
    'O cupom aplicado tem desconto menor que o atual do curso',
  'promoCode.discount': 'O desconto do cupom aplicado é de {discount}%',
  'promoCode.discountAmount':
    'O desconto do cupom aplicado é de {discountAmount}',
  'promoCode.freeCourse':
    '| O cupom aplicado oferece um curso gratuito de {freeCourse} | O cupom aplicado oferece uns cursos gratuitos de {freeCourse}',

  // Partners section
  'partnersSection.h2': 'Nossos Parceiros',
  'partnersSection.text':
    'Uma série de acordos entre a EBAC e parceiros da iniciativa pública e privada permitem criar programas educacionais baseados nas demandas atuais e futuras do mercado, proporcionando aos alunos experiência de trabalho com projetos reais.',

  // recommend component
  'recommend.title': 'Você também pode gostar',

  // mainSearch component
  'mainSearch.placeholder': 'Busca',
  'mainSearch.search': 'Search',
  'mainSearch.clear': 'Clear search',

  // filtersContent component
  'filtersContent.filter': 'Filtro',
  'filtersContent.courseType': 'Tipo de curso',
  'filtersContent.courseType.profession': 'Profissão',
  'filtersContent.courseType.profession.text':
    'Programa de longa duração com formação abrangente que proporciona um domínio de todos os aspectos da área de atuação escolhida. Você também poderá construir seu portfólio, criar seu currículo e preparar-se para garantir uma colocação no mercado de trabalho.',
  'filtersContent.courseType.course': 'Curso',
  'filtersContent.courseType.course.text':
    'Programa de curta duração que permitirá a sua entrada numa especialização na área de atuação escolhida aprendendo habilidades específicas do nível básico ao avançado que você irá usar em seu portfólio ou crescimento profissional.',
  'filtersContent.EBACAdvantages': 'EBAC Vantagens',
  'filtersContent.jobGuarantee': 'Garantia de Emprego',
  'filtersContent.duration': 'Duração',
  'filtersContent.duration.from': 'De',
  'filtersContent.duration.to': 'a',
  'filtersContent.duration.months': 'meses',
  'filtersContent.apply': 'Aplicar',
  'filtersContent.free': 'Curso gratuito',
  'filtersContent.topSale': 'Campeão de vendas',

  // TODO Pt
  'topicsFilter.title': 'Tópicos',
  'topicsFilter.more': '{count} outros tópicos',
  'topicsFilter.less': 'Colapso',
  'topicsFilter.notFound.direction':
    'Este tópico não funciona na direcção {direction} :(',
  'topicsFilter.search': 'Pesquise por tópicos',
  'topicsFilter.notFound.search': `Sua consulta por "{search}" não encontrou resultados`,

  // saleBlock component
  'saleBlock.mobileHeadingPrefix': 'Сompre um curso, ganhe o outro',
  'saleBlock.mobileHeading': '',
  'saleBlock.mobileHeadingPostfix': '',
  'saleBlock.desktopHeadingPrefix': '',
  'saleBlock.desktopHeading': 'Сompre um curso, ganhe o outro',
  'saleBlock.desktopHeadingPostfix': '',
  'saleBlock.discountTitle': '',
  'saleBlock.button.label': 'ESCOLHA SEU CURSO',
  'saleBlock.mobileHeading.percentPrefix': '',
  'saleBlock.mobileHeading.percentAdditional': ' ',
  'saleBlock.mobilePercent': '',
  'saleBlock.desktopPercent': '',

  'saleBlock.eternal.mobileHeadingPrefix': 'Invista em você com até',
  'saleBlock.eternal.mobileHeading': ' 60% OFF',
  'saleBlock.eternal.mobileHeadingPostfix': '',
  'saleBlock.eternal.desktopHeadingPrefix': '',
  'saleBlock.eternal.desktopHeading': 'Invista em você',
  'saleBlock.eternal.desktopHeadingPostfix': '',
  'saleBlock.eternal.discountTitle': 'Desconto até',
  'saleBlock.eternal.button.label': 'ESCOLHA SEU CURSO',
  'saleBlock.eternal.mobilePercent': '',
  'saleBlock.eternal.desktopPercent': '60%',

  'saleBlock.landing.button.label': 'INSCREVA-SE',

  ambassadorsPage: {
    ambassadorsSection: {
      sectionTitle: 'Nossos Embaixadores',
    },
  },

  // basket component
  'basket.mobileHeader': 'Cursos escolhidos',

  // index page
  'index.main-hero-section.h1':
    'APRENDIZADO NA PRÁTICA: SAIA PRONTO PARA TRABALHAR',
  'index.main-hero-section.h2':
    'Os melhores cursos que farão você decolar em sua carreira',
  'index.main-hero-section.directions.title': 'Veja nossas áreas:',

  'index.main-courses-section.h2': 'Cursos Online',
  'index.main-courses-section--new.h2': 'Os nossos novos cursos',
  'index.main-courses-section.subtitle':
    'Programas com conteúdo desenvolvido no formato de videoaulas, ideais para quem deseja se destacar no mercado de trabalho e quer aprender novas habilidades para evoluir profissionalmente.',
  'index.main-courses-section--new.subtitle': '',
  'index.main-courses-section.btn': 'VER todos OS cursos',
  'index.main-courses-section.profissao': 'Profissão',
  'index.main-courses-section.curso': 'Curso',

  'index.main-webinars-section.h2': 'Webinars Gratuitos',
  'index.main-webinars-section.subtitle':
    'Grandes profissionais discutem temas relevantes e seus impactos no mercado de trabalho em transmissões ao vivo.',
  'index.main-webinars-section.btn': 'VER todos OS Webinars',

  'index.main-blog-section.h2': 'Blog',
  'index.main-blog-section.btn': 'VER todos OS posts',

  'index.main-reviews-section.h2': 'Avaliação dos alunos',

  'index.main-plan-section.h2': 'Como é a rotina dos cursos',

  'index.main-plan-section.1.h3': 'Aprenda sobre o tema',
  'index.main-plan-section.1.description':
    'Cursos compostos por videoaulas detalhadas e de fácil entendimento',
  'index.main-plan-section.2.h3': 'Coloque o aprendizado em prática',
  'index.main-plan-section.2.description':
    'Atividades para praticar no ritmo mais apropriado para você',
  'index.main-plan-section.3.h3': 'Troque&nbsp;conhecimento com o tutor',
  'index.main-plan-section.3.description':
    'Correção individual dos trabalhos e feedback para melhorias',
  'index.main-plan-section.4.h3': 'Apresente um trabalho autoral',
  'index.main-plan-section.4.description':
    'Excelente oportunidade para enriquecer seu portfólio',

  'index.main-info-section.description':
    'A Escola Britânica de Artes Criativas e Tecnologia (EBAC) é uma instituição inovadora de ensino superior em Artes Criativas e Tecnologia que oferece cursos online, além de programas presenciais e híbridos de graduação, especialização e iniciação.',

  'index.main-info-section.1.title': '+150',
  'index.main-info-section.1.description':
    'cursos livres e ensino superior nas áreas de TI, design, marketing, audiovisual, fashion e negócios',

  'index.main-info-section.2.title': '+95000',
  'index.main-info-section.2.description':
    'alunos estudando com nossos cursos online',

  'index.main-info-section.3.title': '100%',
  'index.main-info-section.3.description':
    'dos professores e coordenadores são profissionais atuantes no mercado',

  'index.main-info-section.4.title': '+40',
  'index.main-info-section.4.description':
    'empresas parceiras colaboram no desenvolvimento dos cursos, projetos reais e programas de estágio',

  'index.main-form-section.h2': 'Faça seu cadastro',
  'index.main-form-section.description':
    'Cadastre-se no nosso mailing e receba, em primeira mão, novidades sobre os cursos online, convites para eventos e webinars gratuitos.',
  'index.main-form-section.name.label': 'Nome',
  'index.main-form-section.name.error': 'Digite seu nome',
  'index.main-form-section.email.label': 'Email',
  'index.main-form-section.email.error': 'Digite seu e-mail',
  'index.main-form-section.privacy-policy.part1':
    'Clicando em enviar você aceita os termos e condições da',
  'index.main-form-section.privacy-policy.part2': 'Política de Privacidade',
  'index.main-form-section.btn': 'Enviar',

  'index.success-dialog.title': 'Sucesso!',
  'index.success-dialog.description':
    'Obrigado por sua inscrição! Em\xa0breve um\xa0dos nossos especialistas irá entrar em\xa0contato com você.',
  'index.success-dialog.btn': 'Ok, fechar',

  'index.upsell-dialog.h3':
    'Solicite o contato de um consultor e receba condições especiais para se matricular agora mesmo!',
  'index.upsell-dialog.name.label': 'Nome',
  'index.upsell-dialog.name.error': 'Digite seu nome',
  'index.upsell-dialog.btn': 'SOLICITAR CONTATO',
  'index.register.error.email': 'Digite seu e-mail',

  // about-us page
  'about-us.title': 'Sobre nós',
  'about-us.description-part-1':
    'A Escola Britânica de Artes Criativas & Tecnologia (EBAC) é uma instituição de ensino inovadora que oferece cursos online, além de programas de pós-graduação e especialização. Foi fundada em uma parceria com a University of Hertfordshire, uma das instituições de ensino mais renomadas do Reino Unido. Em 2021, 2022 e 2023, fomos reconhecidos como uma das 100 edtechs mais promissoras da América Latina.',
  'about-us.description-part-2':
    'Com um corpo docente formado por excelentes profissionais atuantes no mercado do Brasil e Latam, nossa escola oferece um novo modelo de ensino das disciplinas que envolvem criatividade, tecnologia, marketing, audiovisual e negócios, além de uma série de iniciativas que preparam o aluno para sua inserção no mercado de trabalho.',

  'about-us.areas.h2': 'Nossas áreas de ensino',
  'about-us.areas.card-item-1.title': 'Design',
  'about-us.areas.card-item-1.description':
    'Elabore projetos em UX/UI, design de interiores, design gráfico, paisagismo, iluminação, entre outras áreas',
  'about-us.areas.card-item-2.title': 'Audiovisual',
  'about-us.areas.card-item-2.description':
    'Seja um profissional que atua em sets de filmagem, edição de vídeo e efeitos visuais',
  'about-us.areas.card-item-3.title': 'Marketing',
  'about-us.areas.card-item-3.description':
    'Domine conhecimentos do marketing digital e conheça diferentes estratégias online',
  'about-us.areas.card-item-4.title': 'Software',
  'about-us.areas.card-item-4.description':
    'Avance na sua carreira com conhecimento técnico em softwares específicos',
  'about-us.areas.card-item-5.title': 'Programação & Data',
  'about-us.areas.card-item-5.description':
    'Aprenda linguagens de programação, códigos, algoritmos e gerenciamento de dados',
  'about-us.areas.card-item-6.title': 'Games',
  'about-us.areas.card-item-6.description':
    'Gerencie projetos em games, desenvolva jogos, personagens e cenários em 2D e 3D, com interatividade',
  'about-us.areas.card-item-7.title': 'Negócios',
  'about-us.areas.card-item-7.description':
    'Implemente uma gestão estratégica e inovadora para garantir o sucesso dos seus projetos nas mais diversas áreas.',

  'about-us.list.item-1.title': 'Aprenda na prática',
  'about-us.list.item-1.description':
    'Formação completa através do método “Aprenda Fazendo” reunindo teoria e prática',
  'about-us.list.item-2.title': 'Feedback profissional',
  'about-us.list.item-2.description':
    'Receba o acompanhamento necessário e feedback individualizado de nossos tutores',
  'about-us.list.item-3.title': 'Atualização imediata',
  'about-us.list.item-3.description':
    'Conteúdo atualizado para que você esteja por dentro das principais tendências do mercado',
  'about-us.list.item-4.title': 'Portfólio<br/>& Certificado',
  'about-us.list.item-4.title-without-wrap': 'Portfólio & Certificado',
  'about-us.list.item-4.description':
    'Construa seu próprio portfólio com projetos autorais e obtenha seu certificado de conclusão',

  'about-us.reviews.h2': 'Avaliação dos alunos',

  'about-us.places.h2': 'Estudantes de todo<br/> o Brasil e do mundo',
  'about-us.places.h2-without-wrap': 'Estudantes de todo o Brasil e do mundo',
  'about-us.places.list.item-1': 'São Paulo',
  'about-us.places.list.item-2': 'Curitiba',
  'about-us.places.list.item-3': 'Rio de Janeiro',
  'about-us.places.list.item-4': 'Porto Alegre',
  'about-us.places.list.item-5': 'Recife',
  'about-us.places.list.item-6': 'Miami',
  'about-us.places.list.item-7': 'Brasília',
  'about-us.places.list.item-8': 'Porto',
  'about-us.places.list.item-9': 'Salvador',
  'about-us.places.list.item-10': 'Florianópolis',
  'about-us.places.list.item-11': 'Belo Horizonte',
  'about-us.places.list.item-12': 'Belém',
  'about-us.places.list.item-13': 'Los Angeles',
  'about-us.places.list.item-14': 'Lisboa',
  'about-us.places.list.item-15': 'Manaus',
  'about-us.places.list.item-16': 'Natal',

  'about-us.experts.title': 'Nossos professores são top de mercado',
  'about-us.experts.description':
    'Na EBAC, o quadro de professores é composto por especialistas com experiência prática e atual nos mercados onde atuam. Todos reúnem reconhecimentos, como prêmios e projetos para clientes de grande visibilidade.',

  // career-center-company page
  'career-center-company.h2': 'Centro de carreiras',
  'career-center-company.description':
    'A EBAC auxilia sua empresa a expandir a base de candidatos e facilita o recrutamento de profissionais dos mercados criativo e tecnológico gratuitamente. Desta maneira, fortalecemos sua marca empregadora e colaboramos no desenvolvimento de talentos internos. Veja como:',

  'career-center-company.block-1.title': 'Recrutamento',
  'career-center-company.block-1.description':
    'Novos alunos concluem nossos cursos semanalmente, criando assim uma plataforma robusta de potenciais candidatos para diversas oportunidades.<br><br>Auxiliamos a sua empresa a encontrar o candidato ideal de acordo com a sua cultura corporativa e potenciais necessidades de negócio.',
  'career-center-company.block-1.list-1.content':
    'Encaminhe para a EBAC as vagas disponíveis',
  'career-center-company.block-1.list-2.content':
    'Alinhe conosco o perfil desejado e os principais requisitos',
  'career-center-company.block-1.list-3.content':
    'Receba currículos e portfólios dos candidatos mais adequados ao perfil de cada oportunidade',

  'career-center-company.block-2.title': 'Tudo isso, de graça!',
  'career-center-company.block-2.description':
    'Seu business case será o projeto final de nossos alunos. Assim, eles terão o primeiro contato com a sua empresa para entender o dia a dia como parte do time e também contribuir para o negócio.',
  'career-center-company.block-2.list-1.content':
    'Descreva a sua necessidade ou problema a ser compartilhado com os alunos',
  'career-center-company.block-2.list-2.content':
    'Eles (os alunos) trabalharão com base em seu caso durante o projeto final',
  'career-center-company.block-2.list-3.content':
    'Apresentamos os projetos e alunos com os melhores resultados',

  'career-center-company.accent-block.part1':
    'Além de ser um processo totalmente gratuito, a sua empresa pode sair ganhando, investindo na imagem de empregador que possui no mercado.',
  'career-center-company.accent-block.part2':
    'Podemos divulgar em nossas redes sociais os resultados e benefícios dessa parceria, além de utilizar nossos webminars frequentes para aumentar a visibilidade e interesse na sua marca.',

  'career-center-company.block-3.title': 'Desenvolvimento de talentos internos',
  'career-center-company.block-3.description':
    'Precisa desenvolver talentos internos, implementar programas de estágio e trainee ou promover a requalificação de colaboradores dentro da sua organização? Podemos ajudar a escolher um curso certo para seus colaboradores ou adaptá-lo de acordo com as necessidades específicas de cada área da empresa.',
  'career-center-company.block-3.list-1.content':
    'Escolha a área de seu negócio que necessita promover ações de desenvolvimento e promoção de talentos',
  'career-center-company.block-3.list-2.content':
    'Descreva suas metas para cada área ou departamento',
  'career-center-company.block-3.list-3.content':
    'Promova adequadamente as ações de educação customizadas para as áreas indicadas',

  // career-center-students page
  'career-center-students.h2': 'Centro de carreiras',
  'career-center-students.description.h3': '“Concluí o meu curso e agora?”',
  'career-center-students.description.subtitle':
    'A EBAC trabalha para que você alcance seus objetivos!',
  'career-center-students.description.body-m':
    'Estamos 100% focados na trajetória de nossos alunos não apenas ao longo de seus estudos, mas também na hora de ingressar no mercado de trabalho. O nosso centro de carreiras é feito por um time de profissionais que trabalha em diversas frentes em prol do futuro de quem estuda com a gente.',
  'career-center-students.step-1.title':
    'Recrutadores profissionais auxiliam você a estruturar seu currículo e se preparar para entrevistas',
  'career-center-students.step-1.description':
    'Temos uma equipe de especialistas da área de recursos humanos com ampla experiência em recrutamento e seleção em diferentes setores prontos para compartilhar seus conhecimentos.<br><br>Trabalharemos com você os principais temas como a estruturação do seu currículo e perfil no LinkedIn, inseguranças na hora das entrevistas, mapeamento de habilidades para identificar as suas principais forças, entre outros aspectos dos processos seletivos.<br><br>*Consulte a elegibilidade na landing page do curso de interesse ou com um dos nossos consultores',
  'career-center-students.step-2.title':
    'Seu projeto final realizado em colaboração com nossas empresas parceiras',
  'career-center-students.step-2.description':
    'A EBAC está ampliando constantemente sua rede de parcerias. São empresas para as quais os alunos são indicados e onde, ao final de seus projetos de conclusão baseados em cases reais, podem trabalhar. Os estudantes com os melhores projetos são avaliados e podem começar uma nova etapa de suas vidas profissionais já empregados.',
  'career-center-students.step-3.title':
    'Seleção das melhores vagas para os nossos alunos',
  'career-center-students.step-3.description':
    'Monitoramos em tempo real as vagas disponíveis no mercado de trabalho a fim de apresentar os currículos e portfólios de nossos estudantes aos times de seleção.\n\n*Consulte a elegibilidade na landing page do curso de interesse ou com um dos nossos consultores',
  'career-center-students.step-4.title':
    'Mentoria com recrutadores e profissionais das empresas mais sonhadas pelos candidatos',
  'career-center-students.step-4.description':
    'Convidamos para os nossos webinars os recrutadores das empresas mais desejadas para se trabalhar. Eles compartilharão os seus segredos: no que mais reparam durante o processo seletivo, tipos de avaliação, planos de carreira e muito mais.<br><br>Por isso, todo o seu foco deve estar voltado para as aulas! Estude, tire suas dúvidas com os tutores, assista aos nossos webinars e faça um excepcional projeto final. Isso já é boa parte do caminho andado!<br><br>O resto você pode deixar com a gente. Nós ajudaremos a sua carreira decolar!',

  // vacancies page
  'vacancies.h2': 'Trabalhe conosco',
  'vacancies.description':
    'A EBAC ensina às pessoas as profissões mais modernas e demandadas pelo mercado. Todos nossos esforços são voltados à melhoria da qualidade de ensino e à expansão da abrangência dos nossos cursos. Isto só acontece graças ao nosso time de profissionais dedicados e comprometidos com a ideia de criar uma plataforma de ensino online que te faça querer estudar de verdade.',
  'vacancies.vacanciesListTitle':
    'Se você quiser se juntar ao time da EBAC, veja aqui as nossas oportunidade abertas:',
  'vacancies.noVacancies.part1':
    'Oops, parece que não temos nenhuma vaga disponível no momento.',
  'vacancies.noVacancies.part2':
    'Envie seu currículo e entraremos em contato assim que surgirem novas oportunidades.',
  'vacancies.emailText':
    'Se você não identificou nenhuma oportunidade em aberto na sua área de atuação, se apresente para nós por e-mail:',
  'vacancies.email': 'carreer@ebac.art.br',
  'vacancies.directions.0': 'Todas as vagas',

  // sale page
  'sale.h1': 'Nós te amamos muito mesmo!',
  'sale.subtitle.part1': 'Desconto de 50%',
  'sale.subtitle.part2':
    'em todos os cursos online. Tá na hora de dar aquele salto na carreira e conquistar seu diferencial no mercado de trabalho. Garanta já seu curso na EBAC Promo Week!',

  'sale.endOfPromotion': 'Fim da Promoção',
  'sale.filter': 'Filtro',
  'sale.off': 'OFF',
  'sale.noInterestOnCardPart1': 'de',
  'sale.noInterestOnCardPart2': 'sem juros \n no cartão',
  'sale.installmentInUpToPart1': 'Parcelamento em até',
  'sale.installmentInUpToPart2': 'vezes',
  'sale.installmentInUpToPart3': 'no cartão',
  'sale.selectionHelp': 'Está com dúvidas?',
  'sale.placeAnOrder': 'Fechar compra',
  'sale.discountedAmount': 'Valor com desconto',
  'sale.content.section.professions.title': 'Profissões',
  'sale.content.section.courses.title': 'Cursos',
  'sale.product.add': 'Adicionar',
  'sale.product.soldOut': 'Esgotado',
  'sale.product.remove': 'Remover do carrinho',
  'sale.product.moreDetails': 'Mais sobre o curso',
  'sale.product.getCourse': 'Obter curso',
  'sale.product.requestInProgress':
    'Sua solicitação de acesso ao curso está em andamento. Você receberá um e-mail assim que liberarmos o curso para você',
  'sale.product.study': 'Estudar',
  'sale.content.more': 'Mais',
  'sale.h2': 'A gente ajuda na escolha do melhor curso para você',
  'sale.description':
    'Precisa de mais informações para escolher o curso perfeito para você? Preencha seus dados e um de nossos consultores entrará em contato.',
  'sale.name.label': 'Nome',
  'sale.name.error': 'Digite seu nome',
  'sale.email.label': 'Email',
  'sale.email.error': 'Digite seu e-mail',
  'sale.promocode.label': 'Tenho código promocional',
  'sale.promocode.error': 'Promocode error message',
  'sale.privacy-policy.part1':
    'Clicando em enviar você aceita os termos e condições da',
  'sale.privacy-policy.part2': 'Política de Privacidade',
  'sale.btn': 'Enviar',
  'sale.detailProductDialog.courseProgram': 'Programa do Curso',
  'sale.detailProductDialog.professionProgram': 'Programa de profissão',
  'sale.detailProductDialog.skillsBlockTitle': 'O que você irá aprender',
  'sale.downloadCourseProgram': 'Baixe o programa do curso',
  'sale.trailer': 'Trailer do Curso',
  'sale.detailProductDialog.teachers': 'Professores',
  'sale.detailProductDialog.footer.addButton': 'Adicionar ao carrinho',
  'sale.detailProductDialog.footer.addedButton':
    'Adicionado. Ir para o carrinho',
  'sale.detailProductDialog.footer.closeButton': 'Veja outros cursos',
  'sale.basketDialogContent.title': 'Seu pedido',
  'sale.basketDialogContent.total': 'Total',
  'sale.basketDialogContent.buttonLabel': 'Continuar',
  'sale.basketDialogContent.promocodeButtonLabel': 'Inserir',
  'sale.basketDialogContent.discountedValue': 'Valor com desconto',
  'sale.basketDialogContent.promocodeValue': 'Valor com código promocional',
  'sale.basketDialogContent.promocode.valid':
    'Código de promoção aplicado com sucesso',
  'sale.basketDialogContent.promocode.exception':
    'Aconteceu um erro, entre em contato com info@ebaconline.com.br',
  'sale.basketDialogContent.promocode.couponNotFound':
    'O cupom não foi encontrado',
  'sale.basketDialogContent.promocode.couponUsed':
    'O limite de uso deste cupom acabou',
  'sale.basketDialogContent.promocode.couponCannotBeUsedForProduct':
    'Este cupom não é válido para o curso',
  'sale.basketDialogContent.promocode.couponIsNotActive':
    'Este cupom não está ativo',
  'sale.basketDialogContent.promocode.couponDidNotStart':
    'Este cupom não é válido',
  'sale.basketDialogContent.promocode.couponExpired':
    'Este cupom não pode ser usado porque sua validade expirou',
  'sale.basketDialogContent.promocode.couponCannotBeUsedForNomenclature':
    'Este cupom não pode ser aplicado',
  'sale.basketDialogContent.promocode.smallDiscount':
    'O cupom aplicado tem desconto menor que o atual do curso',
  'sale.basketDialogContent.promocode.discount':
    'Código de promoção aplicado com sucesso',
  'sale.basketDialogContent.promocode.discountAmount':
    'Código de promoção aplicado com sucesso',
  'sale.basketDialogContent.promocode.freeCourse':
    'Código de promoção aplicado com sucesso',
  'sale.basketDialogContent.installmentPlansUpTo': 'Parcelamento em até',
  'sale.basketDialogContent.installmentPlansUpTo.description.part1':
    'x12 vezes',
  'sale.basketDialogContent.installmentPlansUpTo.description.part2':
    'no cartão',
  'sale.free': 'Grátis',
  'sale.thx.thx': 'Obrigado!',
  'sale.thx.description.part1':
    'Agradecemos sua inscrição na EBAC. Em breve, você vai receber um email com mais informações.',
  'sale.thx.description.part2':
    'Ah, não deixe de checar a sua caixa de SPAM. Se você quiser finalizar sua matrícula agora e garantir seu desconto desde já, você pode optar pelo pagamento online. O link vai ser exibido em breve.',
  'sale.thx.description.part3': 'Atenção: esta promoção é válida apenas até',
  'sale.thx.description.part4': 'às',
  'sale.thx.loading': 'Carregando...',
  'sale.thx.endEnrollment': 'FINALIZAR MATRÍCULA',
  'sale.companyCatalogDialogContent.title': 'Advertência',
  'sale.companyCatalogDialogContent.content':
    'Você deve estar autorizado no LMS',
  'sale.companyCatalogDialogContent.btn': 'Ok, fechar',
  'sale.badgeFirst': 'Oferta exclusiva',
  'sale.badgeSecond.part1': 'Descontos',
  'sale.badgeSecond.part2': 'de até 60%',

  // courses page
  'courses.professions': 'Profissões',
  'courses.courses': 'Cursos',
  'courses.profession': 'Profissão',
  'courses.course': 'Curso',
  'courses.more': 'Mais',
  'courses.notFound': 'Não foram encontrados resultados para a sua pesquisa',
  'courses.postGraduate': 'Pós-Graduação',

  'courses.title': 'Cursos online',

  'courses.filter.emptyResults':
    'Não foram encontrados resultados para a sua pesquisa',

  'courses.filter.directions.items.todos': 'Todos',

  'courses.filter.search.placeholder': 'Busca',
  'courses.filter.types.title': 'Tipo de curso',

  'courses.filter.types.items.todos': 'Todos',
  'courses.filter.types.items.course': 'Curso',
  'courses.filter.types.items.profession': 'Profissão',

  'courses.filter.duration.title': 'Duração',
  'courses.filter.topics.title': 'Tópico',
  'courses.filter.topics.more': '{count} mais tópicos',
  'courses.filter.topics.less': 'Colapso',
  'courses.filter.topics.search': 'Pesquise por tópicos',

  'vantagens.title': 'EBAC Vantagens',
  'vantagens.jobGuarantee': 'Garantia de Emprego',
  'vantagens.topSale': 'Campeão de vendas',
  'vantagens.free': 'Curso gratuito',
  'vantagens.soldOut': 'Esgotado',

  'courses.filter.mobile.toggler': 'Filtro',
  'courses.filter.mobile.heading': 'Filtro',
  'courses.filter.mobile.apply': 'Aplicar',

  'courses.form.title.1': 'Faça seu cadastro',
  'courses.form.title.2': '',
  'courses.form.text':
    'Cadastre-se no\xa0nosso mailing e\xa0receba, em\xa0primeira mão, novidades sobre os\xa0cursos online, convites para eventos e\xa0webinars gratuitos.',
  'courses.form.placeholder.name': 'Nome',
  'courses.form.placeholder.email': 'Email',
  'courses.form.sendButton': 'Enviar',

  'courses.filter.selected':
    'Filtros | {count} filtro selecionado | {count} filtros selecionados',

  'courses.filter.checkOurTopSaleCourses':
    'Hmm... parece que você não encontrou o que precisava, mas dê uma olhada nos nossos cursos mais populares:',

  'courses.cards.profession.title': 'Profissões',
  'courses.cards.course.title': 'Cursos',
  'courses.cards.default.action.main': 'Mais sobre o curso',

  'courses.cards.sale.action.main': 'Adicionar',
  'courses.cards.sale.action.additional': 'Mais sobre o curso',

  'courses.cards.badges.type.0': 'Curso',
  'courses.cards.badges.type.1': 'Profissão',

  'courses.cards.duration': '{duration} meses',
  'courses.cards.more': 'Mais',

  // sale event types
  'sale.event_type.webinar': 'DO WEBINAR',
  'sale.event_type.marathon': 'DA MARATONA',
  'sale.event_type.workshop': 'DO WORKSHOP',
  'sale.event_type.discussion': 'DO DICAS DE CARREIRA',
  'sale.event_type.demonstration': 'DA DEMONSTRAÇÃO',
  'sale.event_type.success_case': 'DO CASE DE SUCESSO',
  'sale.event_type.masterclass': 'DA MASTERCLASS',
  'sale.event_type.immersion': 'DA IMERSÃO',
  'sale.nomenclature_type.0': 'Curso',
  'sale.nomenclature_type.1': 'Profissão',
  'sale.nomenclature_type.0.en': 'Course',
  'sale.nomenclature_type.1.en': 'Profession',

  acceptTheTermsAndConditions: 'Eu li e aceito os termos e condições da',
  privacyPolicyAndServiceAgreement:
    'Política de Privacidade e Contrato de Prestação de Serviço',

  'formText.part1': 'Eu li e aceito os termos e condições da',
  'formText.part2':
    'Política de Privacidade e Contrato de Prestação de Serviço',

  'webinarSale.badge.title': 'de desconto',
  'webinarSale.first.title': 'VENDA EXCLUSIVA PARA PARTICIPANTES',
  'webinarSale.first.subtitle': 'Promoção por tempo limitado',
  'webinarSale.timer.title': 'Até o fim da promoção:',
  'webinarSale.timer.days': 'days',
  'webinarSale.timer.hours': 'hours',
  'webinarSale.timer.minutes': 'minutes',
  'webinarSale.timer.seconds': 'seconds',
  'webinarSale.second.subtitle': '{value} de desconto!',
  'webinarSale.price.first.title': 'Preço original',
  'webinarSale.price.first.economy': 'Você economiza',
  'webinarSale.price.second.title': 'Preço com desconto',
  'webinarSale.price.second.subtitle': 'sem juros',
  'webinarSale.sendButton': 'Marque sua consulta gratuita',
  'webinarSale.dialog.title': 'Faça sua inscrição para o curso',
  'webinarSale.dialog.field.name.label': 'Nome',
  'webinarSale.dialog.field.name.error': 'Digite seu nome',
  'webinarSale.dialog.field.email.error': 'Digite seu e-mail',
  'webinarSale.dialog.sendButton': 'Marque sua consulta gratuita',

  'webinarSale.special.title': 'Oferta especial!',
  'webinarSale.special.subtitle.1':
    'Desconto disponível até o\xa0último dia de\xa0evento ao\xa0vivo.',
  'webinarSale.special.subtitle.2':
    'Você pode usar esse desconto no\xa0curso que escolher.',
  webinarsCount: '{n} o dia',

  'thankYouPage.title': 'Obrigado!',
  'thankYouPage.description.part1':
    'Obrigado pela sua inscrição, você receberá um email em breve com mais detalhes.',
  'thankYouPage.description.part2':
    '(Ah! Não deixe de checar sua caixa de spam)',
  'thankYouPage.description.part3': 'Quer finalizar sua matrícula agora?',
  'thankYouPage.description.part4':
    'Faça o pagamento online. É simples, rápido e seguro.',
  'thankYouPage.btn.label': 'Finalizar matrícula',
  'thankYouPage.btn.loading': 'Carregando...',

  'thankYouPage.contract.0': 'Eu li e aceito os termos e condições da',
  'thankYouPage.contract.1':
    'Política de Privacidade e Contrato de Prestação de Serviço',

  // Offers
  'offer.metaTitle': 'Oferta pessoas! --- EBAC Online',
  'offer.title': 'Oferta pessoal!',
  'offer.description':
    'Para obter todos os cursos por um preço especial, pague o valor clicando no botão "ao lado do pagamento". Após isso, o acesso à sua conta pessoal chegará ao seu e-mail.',
  'offer.orderId': 'Ordem {id}',
  'offer.courses': 'Cursos',
  'offer.payAll': 'Ao lado do pagamento',
  'offer.pay': 'Pagar',
  'offer.price.courses': 'Cursos ({count})',
  'offer.price.discount': 'Desconto',
  'offer.price.total': 'Total',

  'offer.paymentProgress': 'Progresso do pagamento',
  'offer.paymentLink.part': 'Parte {index}',
  'offer.payment.status.success': 'Pago',
  'offer.paymentSuccess.title': 'Parabéns!',
  'offer.paymentSuccess.text':
    'O curso é totalmente pago. Dentro de cinco minutos, você receberá um e-mail com nome de usuário e senha para acessar sua conta pessoal.',
  'offer.paymentСanceled.title': 'A oferta foi cancelada.',
  'offer.paymentСanceled.text': '',

  'offer.paymentLinksNotFound': 'Links de pagamento não encontrados.',

  'offer.payment.successPage.title': 'Payment Successful!',
  'offer.payment.successPage.description.part1':
    'Your payment has been made, you will receive an email shortly with more details.',
  'offer.payment.successPage.description.part2':
    '(Ah! Be sure to check your spam folder)',
  'offer.payment.successPage.description.part3':
    'Perhaps you will like our other courses?',
  'offer.payment.successPage.btn1': 'View other courses',

  'offer.payment.failurePage.title': 'Payment Failure!',
  'offer.payment.failurePage.description.part1':
    'An error occurred during payment, carefully check your payment details and try again',
  'offer.payment.failurePage.description.part2':
    'If you are sure that your data is correct, please contact support',

  // END Offers

  'webinar.directions.all.eventMetaDescription':
    'Os Webinars irão ajudá-lo a dominar as habilidades em design, programação e marketing. Inscreva-se nos eventos e assista às transmissões anteriores.',
  'webinar.directions.all.eventMetaTitle':
    'Webinars gratuitos e eventos EBAC Online',
  'webinar.directions.all.eventTitle': 'Webinars Gratuitos',

  'webinar.search.notFound':
    'Não foram encontrados resultados para a sua pesquisa',
  'webinar.search.more': 'Mais eventos',
  'webinar.type.discussion': 'Dicas de carreira',
  'webinar.type.success_case': 'Cases de sucesso',
  'webinar.type.webinar': 'Webinar',
  'webinar.type.workshop': 'Workshop',
  'webinar.type.marathon': 'Maratona',
  'webinar.type.demonstration': 'Demonstração',
  'webinar.type.event': 'Evento',
  'webinar.type.masterclass': 'Masterclass',
  'webinar.type.immersion': 'Imersão',

  'webinar.chat.title': 'Chat',
  'webinar.chat.register': 'Participe da discussão',
  'webinar.chat.close': 'Ocultar chat',
  'webinar.chat.open': 'Abrir chat',

  'webinar.schedule.title': 'Programação por dia',
  'webinar.speaker.title': 'Palestrante',

  'webinar.topic.status.upcoming': 'Em breve',
  'webinar.topic.status.live': 'Ao Vivo',
  'webinar.topic.status.passed': 'Transmitido',

  'webinar.preRegister.title':
    'Para acessar o evento, use o link de qualquer um dos e-mails que você recebeu após sua inscrição',
  'webinar.preRegister.description':
    'Se você ainda não se inscreveu, agora é a hora!',
  'webinar.preRegister.submit': 'Inscreva-se',

  'webinar.register.title':
    'Preencha com os seus dados.<br/>Enviaremos um link de&nbsp;participação e lembretes por&nbsp;email',
  'webinar.register.label.name': 'Nome',
  'webinar.register.error.name': 'Digite seu nome',
  'webinar.register.label.email': 'Email',
  'webinar.register.error.email': 'Digite seu e-mail',
  'webinar.register.submit': 'INSCREVA-SE',

  'webinar.registered': 'Você está inscrito',

  'webinar.register.thanks.title': 'Obrigada por se inscrever!',
  'webinar.register.thanks.text':
    'Você receberá o&nbsp;email de&nbsp;confirmação com o&nbsp;link de&nbsp;participação em até cinco minutos. Para participar do&nbsp;evento, você pode usar o&nbsp;link de&nbsp;qualquer email que você tenha recebido depois do&nbsp;cadastro. Se você não recebeu o&nbsp;email de&nbsp;confirmação, por&nbsp;favor, cheque a&nbsp;sua pasta de&nbsp;SPAM.',
  'webinar.register.thanks.ok': 'OK',

  'webinar.opinion.title': 'Compartilhe a sua opinião!',
  'webinar.opinion.label.text':
    'Envie seu feedback sobre o evento e ajude-nos a melhorar ainda mais',
  'webinar.opinion.error.text': 'Digite seu comentário',
  'webinar.opinion.label.email': 'E-mail para contato',
  'webinar.opinion.error.email': 'Digite seu e-mail',
  'webinar.opinion.privacy':
    'Clicando em enviar você aceita os termos e condições da',
  'webinar.opinion.privacy.link': 'Política de Privacidade',
  'webinar.opinion.submit': 'Enviar',

  'webinar.other.title': 'Outros Webinars',

  'webinar.promo.register.title': 'Cadastre-se para participar do evento',
  'webinar.promo.register.submit': 'Inscreva-se',
  'webinar.promo.discount': 'Aproveitaram o desconto',

  'webinar.recommends': 'Recomendados',

  'webinar.filter.status.all': 'Todos',
  'webinar.filter.status.upcoming': 'Em breve',
  'webinar.filter.status.finished': 'Anteriores',

  // Blog
  'blog.directions.all.metaDescription':
    'O Blog da EBAC sobre design, marketing, software, programação & data, vídeo, áudio e jogos. Inspire-se com conteúdo especializado para profissionais e iniciantes.',
  'blog.directions.all.metaTitle': 'EBAC | Blog',
  'blog.directions.all.seoText':
    'O Blog da EBAC sobre design, marketing, software, programação & data, vídeo, áudio e jogos. Inspire-se com conteúdo especializado para profissionais e iniciantes.',
  'blog.directions.all.title': 'Blog',

  //Contact Information
  'contactInformation.title': 'Informações de contato',
  'contactInformation.titlePersonal': 'Pessoal',
  'contactInformation.titleContact': 'Contato',
  'contactInformation.titleAddress': 'Endereço',
  'contactInformation.fullName.label': 'Nome completo',
  'contactInformation.fullName.error': 'Digite seu nome completo',
  'contactInformation.identification.label': 'Identificação',
  'contactInformation.identification.error':
    'Digite sua identificação - RG ou CPF',
  'contactInformation.data.label': 'Data de nascimento',
  'contactInformation.data.error': 'Digite sua data de nascimento',
  'contactInformation.email.label': 'Email',
  'contactInformation.email.error': 'Digite seu e-mail',
  'contactInformation.phone.label': 'Telefone',
  'contactInformation.confirmEmail.label': 'Confirme seu e-mail',
  'contactInformation.confirmEmail.error': 'Digite seu e-mail',
  'contactInformation.confirmEmail.confirmError': 'Confirme seu e-mail',
  'contactInformation.zipCode.label': 'CEP',
  'contactInformation.zipCode.error': 'Digite seu CEP',
  'contactInformation.address.label': 'Endereço',
  'contactInformation.address.error': 'Digite seu endereço',
  'contactInformation.country.label': 'País',
  'contactInformation.country.error': 'Digite seu país',
  'contactInformation.number.label': 'Número',
  'contactInformation.number.error': 'Digite seu número',
  'contactInformation.complement.label': 'Complemento',
  'contactInformation.complement.error': 'Digite seu complemento',
  'contactInformation.city.label': 'Cidade',
  'contactInformation.city.error': 'Digite sua cidade',
  'contactInformation.state.label': 'Estados',
  'contactInformation.state.error': 'Digite seu Estados',
  'contactInformation.neighborhood.label': 'Bairro',
  'contactInformation.neighborhood.error': 'Digite seu bairro',
  'contactInformation.cpf.error': 'Digite seu bairro',
  'contactInformation.part1': 'Eu li e aceito os termos e condições',
  'contactInformation.part2':
    'da Política de Privacidade e Contrato de Prestação de Serviço',
  'contactInformation.btn': 'Enviar',
  'contactInformation.loan-request-dialog.success.title': 'Ótimo!',
  'contactInformation.loan-request-dialog.pending.title':
    'Você já preencheu este formulário!',
  'contactInformation.loan-request-dialog.pending.description':
    'Sua solicitação está em análise com o gerente.',
  'contactInformation.loan-request-dialog.error.title': 'Obrigado!',
  'contactInformation.loan-request-dialog.error.description':
    'Solucionaremos o problema em breve. Por favor, tente novamente mais tarde.',
  'contactInformation.loan-request-dialog.btn': 'Ok',

  // Loan-request
  'loanRequest.info.title': 'Pedido de empréstimo',
  'loanRequest.info.total': 'Total',
  'loanRequest.info.instalments': 'Parcelas',
  'loanRequest.info.pagamento': 'Pagamento',
  'loanRequest.needHelp': 'Precisa de ajuda? Nos envie um e-mail',

  'loanRequest.thankyou.title': 'Obrigado!',
  'loanRequest.thankyou.description1':
    'Pagamentos agendados conectados com sucesso!',
  'loanRequest.thankyou.description2': '',

  'loanRequest.fail.title': 'Algo parece ter dado errado!',
  'loanRequest.fail.description1':
    'Pagamentos agendados conectados com sucesso!',
  'loanRequest.fail.description2':
    'Entre em contato com seu gerente ou envie um e-mail para <a href="mailto:suporte@ebaconline.com.br" class="email">suporte@ebaconline.com.br</a>',

  'loanRequest.pay.flywire': 'Pagar com FlyWire',
  'loanRequest.paid.flywire': 'Pago',
  'loanRequest.canceled.flywire': 'Oferta cancelada',

  'loanRequest.instalment.expected': 'Esperado',
  'loanRequest.instalment.paid': 'Pago',

  // Page 404
  'page404.title': 'Opa, página não encontrada!',
  'page404.titleMobile': `Opa,{0}página{0}não{0}encontrada!`,
  'page404.subtitle':
    'Algum erro ocorreu, ou deixamos de remover um link antigo, ou pode ser que você tenha digitado o endereço errado.',
  'page404.text.highlight': `Mas tá tudo bem!`,
  'page404.text.bold': 'Você ainda pode encontrar algo interessante por aqui.',

  'page404.links.home': 'Voltar à página inicial   →',
  'page404.links.courses': 'Cursos',
  'page404.links.professions': 'Profissões',
  'page404.links.freeCourses': 'Cursos gratuitos',
  'page404.links.jobGuarantee': 'Cursos com garantia de emprego',
  'page404.links.upcomingEvents': 'Eventos',
  'page404.links.blogArticles': 'Artigos do Blog',
  'page404.links.aboutUs': 'Sobre nós',

  // aug 2022 redesign
  'rt0822.header.courses': 'Cursos',
  'rt0822.header.company': 'Quem somos',
  'rt0822.header.events': 'Eventos',
  'rt0822.header.blog': 'Blog',
  'rt0822.header.toPartners': 'Para empresas',
  'rt0822.header.toPartners.corporateEducation': 'Educação corporativa',
  'rt0822.header.toPartners.challenges': 'Atração de Talentos',
  'rt0822.header.login': 'Login',

  'rt0822.main.titleGradient': 'A EBAC é um novo começo',
  'rt0822.main.title': 'para a\xa0sua carreira',
  'rt0822.main.subtitle':
    'Somos uma escola online com tudo que você precisa para conquistar profissões em\xa0alta no\xa0mercado de\xa0trabalho',
  'rt0822.main.allCourses': 'Nossos cursos\xa0\xa0\xa0\xa0→',

  'rt0822.footer.directions': 'Áreas',
  'rt0822.footer.projects': 'Projetos',
  'rt0822.footer.blog': 'Blog',
  'rt0822.footer.webinars': 'Eventos',
  'rt0822.footer.about': 'Sobre',
  'rt0822.footer.aboutCompany': 'A\xa0EBAC',
  'rt0822.footer.careerCenter': 'Centro de carreiras',
  'rt0822.footer.vacancies': 'Trabalhe conosco',
  'rt0822.footer.toPartners': 'Para empresas',
  'rt0822.footer.toCorporateClients': 'Educação corporativa',
  'rt0822.footer.toCorporateClients.url': '/para-empresas/corporate-education',
  'rt0822.footer.suggestVacancy': 'Atração de Talentos',
  'rt0822.footer.suggestVacancy.url': '/para-empresas/challenges',
  'rt0822.footer.whatsapp': 'WhatsApp',
  'rt0822.footer.telephone': 'Telefone',
  'rt0822.footer.email': 'E-mail',
  'rt0822.footer.address': 'Ebac Ensino à Distância Ltda.',
  'rt0822.footer.entity':
    'Rua Salem Bechara, 140, conj. 1601, centro, CEP 06018-180, Osasco, SP, Brasil.',
  'rt0822.footer.studentReviews': 'Nossos estudantes',
  'rt0822.footer.EBACInMedia': 'EBAC na mídia',

  'footer.directions': 'Áreas',
  'footer.projects': 'Projetos',
  'footer.about': 'Sobre',
  'footer.webinars': 'Eventos',
  'footer.blog': 'Blog',
  'footer.corporateEducation': 'Educação corporativa',
  'footer.challenges': 'Atração de Talentos',

  'rt0822.jobGuaranteePolicy.title': 'Política de\xa0Emprego Garantido',
  'rt0822.jobGuaranteePolicy.text':
    'Garantimos emprego após a\xa0conclusão de\xa0determinados cursos profissionalizantes ou\xa0o\xa0seu dinheiro de\xa0volta.\n\nA Política de\xa0Emprego Garantido exige dedicação. Será preciso esforço e\xa0foco no\xa0cumprimento integral de\xa0todos os\xa0requisitos do\xa0programa.\n\nAcesse a\xa0lista dos cursos com garantia de\xa0emprego.',
  'rt0822.jobGuaranteePolicy.button': 'Leia os\xa0Termos do\xa0Programa',
  'rt0822.jobGuaranteePolicy.url':
    '/upload/cms/programa_de_empregabilidade.pdf',

  'rt0822.coursesAndProfessions.companyText':
    'Comece do\xa0zero ou\xa0se\xa0especialize',
  'rt0822.coursesAndProfessions.text':
    'Nosso objetivo é\xa0apontar e\xa0facilitar o\xa0caminho até o\xa0emprego dos seus sonhos. Ensinamos\xa0habilidades\xa0práticas que vão ajudar a\xa0iniciar uma nova carreira ou\xa0conquistar a\xa0sua promoção.',
  'rt0822.coursesAndProfessions.courses.title': 'Cursos',
  'rt0822.coursesAndProfessions.courses.text':
    'Aprofunde seus conhecimentos em\xa0uma área específica ou\xa0aprenda a\xa0usar determinadas ferramentas em\xa0nível avançado. Adequado para iniciantes e\xa0futuros especialistas.',
  'rt0822.coursesAndProfessions.courses.button':
    'Escolha entre {coursesCount} cursos',
  'rt0822.coursesAndProfessions.professions.title': 'Profissões',
  'rt0822.coursesAndProfessions.professions.text':
    'Prepare-se para começar um\xa0trabalho totalmente novo. Adequado para quem quer mudar de\xa0vida, mas ainda não tem habilidades na\xa0área desejada.',
  'rt0822.coursesAndProfessions.professions.button':
    'Escolha entre {professionsCount} profissões',

  'rt0822.bestCourse.subtitle': 'Curso campeão de vendas',
  'rt0822.bestCourse.title': 'Comece em TI com emprego garantido',
  'rt0822.bestCourse.description':
    'O curso mais popular, onde você vai se aprofundar em 5 profissões e então será capaz de escolher aquela que gostou mais',
  'rt0822.bestCourse.button': 'Curso de TI online para iniciantes',

  'rt0822.about.fact1.number': '145 mil',
  'rt0822.about.fact1.text': 'alunos conosco neste momento',
  'rt0822.about.fact2.number': '78%',
  'rt0822.about.fact2.text':
    'dos nossos alunos encontram empregos relevantes a\xa0partir dos nossos cursos',
  'rt0822.about.fact3.number': '40',
  'rt0822.about.fact3.text':
    'empresas nos ajudam a\xa0desenvolver nossos programas de\xa0estudo de acordo com as\xa0necessidades do\xa0mercado de\xa0trabalho',

  'rt0822.professors.title':
    'Aprenda com os\xa0melhores especialistas do\xa0mercado',
  'rt0822.professors.text':
    'Convidamos profissionais reconhecidos e\xa0de\xa0empresas renomadas para participar da\xa0criação de\xa0nossos cursos. Ensinamos conforme os\xa0desejos e\xa0as\xa0necessidades do\xa0mercado.',
  'rt0822.professors.name.1': 'André Perez',
  'rt0822.professors.text.1':
    'Senior Data Engineer na\xa0<span class="professors__professor-text--bold">SumUp</span>',
  'rt0822.professors.name.2': 'Giba Moojen',
  'rt0822.professors.text.2':
    'Músico, produtor e fundador do\xa0<span class="professors__professor-text--bold">Nossa Toca</span>',
  'rt0822.professors.name.3': 'Zico Góes',
  'rt0822.professors.text.3':
    'Diretor de desenvolvimento de\xa0conteúdo na\xa0<span class="professors__professor-text--bold">STAR+</span> da\xa0<span class="professors__professor-text--bold">Disney</span>',
  'rt0822.professors.name.4': 'Cleber Cassol',
  'rt0822.professors.text.4':
    'Sênior Software Engineer na\xa0<span class="professors__professor-text--bold">Uber</span>',
  'rt0822.professors.name.5': 'Rosanna Amaral',
  'rt0822.professors.text.5':
    'Gerente de Canais Digitais na\xa0<span class="professors__professor-text--bold">Afya</span>',
  'rt0822.professors.name.6': 'Bruno Andrade',
  'rt0822.professors.text.6':
    'Diretor de Estratégia de\xa0Employee Experience para <span class="professors__professor-text--bold">Qualtrics (SAP Group)</span>',
  'rt0822.professors.name.7': 'Marcos Del Valle',
  'rt0822.professors.text.7':
    'Managing Partner na\xa0<span class="professors__professor-text--bold">Jüssi</span>',
  'rt0822.professors.name.8': 'Gabriel Spaniol',
  'rt0822.professors.text.8':
    'Digital Marketing & E-commerce Director na\xa0<span class="professors__professor-text--bold">Carmen Steffens</span>',
  'rt0822.professors.name.9': 'Daniel Santos',
  'rt0822.professors.text.9':
    'Software Engineer na\xa0<span class="professors__professor-text--bold">JetBridge</span>',
  'rt0822.professors.name.10': 'Chico Adelano',
  'rt0822.professors.text.10':
    'Design Director na\xa0<span class="professors__professor-text--bold">Accenture Interactive</span>',
  'rt0822.professors.name.11': 'Rafael Lomelino',
  'rt0822.professors.text.11':
    'Group Product Manager no\xa0<span class="professors__professor-text--bold">Grupo Boticário</span>',
  'rt0822.professors.name.12': 'Fábio Araújo',
  'rt0822.professors.text.12':
    'Gerente de Qualidade da\xa0<span class="professors__professor-text--bold">Via Varejo</span>',

  'rt0822.countries.title': 'Ensinamos em\xa0todo o\xa0mundo',
  'rt0822.countries.text':
    'Você pode estudar conosco em\xa0português ou\xa0espanhol.',

  'rt0822.countries.brasil.title': 'Brasil',
  'rt0822.countries.brasil.text':
    'Desde <b>agosto de 2020</b>.\n\nMais de <b>150 cursos</b> ministrados em\xa0Programação e\xa0Dados, Marketing, Audiovisual, Design, Negócios, Games, Fashion e\xa0Software.',
  'rt0822.countries.méxico.title': 'México',
  'rt0822.countries.méxico.text':
    'Desde <b>agosto de 2021</b>.\n\nMais de <b>43 cursos</b> ministrados em\xa0Programação e\xa0Dados, Marketing, Design, Games, Fashion e\xa0Software.',
  'rt0822.countries.colômbia.title': 'Colômbia',
  'rt0822.countries.colômbia.text':
    'Desde <b>março de 2022</b>.\n\nMais de <b>43 cursos</b> ministrados em\xa0Programação e\xa0Dados, Marketing, Design, Games e\xa0Software.',
  'rt0822.countries.peru.title': 'Peru',
  'rt0822.countries.peru.text':
    'Desde <b>março de 2022</b>.\n\nMais de <b>43 cursos</b> ministrados em\xa0Programação e\xa0Dados, Marketing, Design, Games e\xa0Software.',

  'rt0822.partners.title': 'Nossos parceiros',
  'rt0822.partners.text':
    'Várias das empresas mais renomadas do mercado dividem sua experiência e participam da criação dos nossos cursos. Além\xa0disso,\xa0elas empregam muitos dos nossos melhores alunos',

  'rt0822.studio.title': 'Garantimos a melhor experiência de\xa0aprendizagem',
  'rt0822.studio.text':
    'Todos os\xa0nossos cursos são criados com base em\xa0pesquisas detalhadas sobre o\xa0mercado de\xa0trabalho. Primeiro, descobrimos as\xa0habilidades mais demandadas e, em\xa0seguida, criamos o\xa0curso para garantir que nossos alunos cumpram esses requisitos.',

  'rt0822.studio.slide1.text':
    'Nossos professores e\xa0tutores têm experiência prática na área em que lecionam. Assim, garantimos as informações e\xa0os\xa0processos mais atualizados do\xa0mercado.',
  'rt0822.studio.slide2.text':
    'Tarefas de\xa0casa e\xa0projetos finais são partes essenciais de\xa0todos os\xa0nossos cursos. Nossos tutores são preparados para fornecer feedbacks personalizados e, ao\xa0final, o\xa0aluno contará com habilidades práticas para além do\xa0conhecimento teórico.',
  'rt0822.studio.slide3.text':
    'Aprender por conta própria pode ser difícil. Filmamos nossos cursos em\xa0estúdio, com câmeras, luzes e\xa0cenários variados para tornar a\xa0experiência mais real e\xa0divertida.',
  'rt0822.studio.slide4.text':
    'Todas as\xa0tarefas são pautadas em\xa0casos reais já resolvidos por nossas empresas parceiras. Não trabalhamos com exercícios ficcionais, mas sim com a\xa0realidade que ajudará a\xa0destacar seu currículo ou\xa0portfólio.',
  'rt0822.studio.slide5.text':
    'Os\xa0consultores do\xa0nosso Centro de\xa0Carreiras estão preparados para guiá-lo em\xa0processos de\xa0recrutamento. Receba orientações para preencher seu perfil no\xa0LinkedIn, criar um\xa0portfólio interessante ou\xa0se\xa0preparar para entrevistas de\xa0emprego.',

  'rt0822.webinars.title':
    'Encontre sua futura profissão com nossos eventos online',
  'rt0822.webinars.text':
    'Indeciso sobre qual profissão escolher? Visite nossos workshops ou maratonas gratuitas. Conheça melhor a\xa0área na\xa0qual tem interesse, faça exercícios práticos e\xa0adicione os\xa0resultados ao\xa0seu portfólio.\n\nE\xa0em\xa0alguns dos nossos eventos, você pode ser selecionado para bolsas de\xa0estudo!',
  'rt0822.webinars.button': 'Nossos eventos',

  'rt0822.webinars.type.discussion': 'Dicas de\xa0carreira',
  'rt0822.webinars.type.success_case': 'Cases de\xa0sucesso',
  'rt0822.webinars.type.webinar': 'Webinar',
  'rt0822.webinars.type.workshop': 'Workshop',
  'rt0822.webinars.type.marathon': 'Maratona',
  'rt0822.webinars.type.demonstration': 'Demonstração',
  'rt0822.webinars.type.event': 'Evento',
  'rt0822.webinars.type.masterclass': 'Masterclass',
  'rt0822.webinars.type.immersion': 'Imersão',

  'rt0822.reviewVideos.title': 'O que dizem nossos alunos',

  'rt0822.popular.title': 'Cursos mais populares',
  'rt0822.popular.text': 'Confira os\xa0preferidos dos nossos alunos.',
  'rt0822.popular.button': 'Mostrar mais',

  'rt0822.popular.course.professionInteriorDesigner':
    'Profissão Interior Designer',
  'rt0822.popular.course.professionGraphicDesigner':
    'Profissão Designer Gráfico',
  'rt0822.popular.course.professionDataAnalyst':
    'Profissão Analista de\xa0Dados',
  'rt0822.popular.course.professionUXUIDesigner': 'Profissão UX/UI designer',
  'rt0822.popular.course.professionFullStackPythonDeveloper':
    'Profissão Desenvolvedor Full Stack Python',
  'rt0822.popular.course.professionSocialMediaManager':
    'Profissão Social Media Manager',
  'rt0822.popular.course.coursePersonalStylist': 'Personal Stylist',
  'rt0822.popular.course.professionPerformanceMarketingManager':
    'Profissão Marketing de\xa0Performance',
  'rt0822.popular.course.courseCopywriting': 'Copywriting',
  'rt0822.popular.course.professionDataScientist':
    'Profissão Cientista de\xa0Dados',

  'rt0822.popular.category.design': 'Design',
  'rt0822.popular.category.fashion': 'Fashion',
  'rt0822.popular.category.marketing': 'Marketing',
  'rt0822.popular.category.programming': 'Programação & Data',

  'rt0822.popular.duration': '{duration} meses',

  'rt0822.form.title.1': 'Difícil escolher?',
  'rt0822.form.title.2': 'Nós podemos te\xa0ajudar',
  'rt0822.form.text':
    'Nossos consultores estão preparados para ajudar na\xa0escolha do\xa0curso mais adequado para você com base nos seus interesses, nível de\xa0conhecimento e\xa0objetivos de\xa0carreira.',

  'rt0822.form.placeholder.name': 'Nome',
  'rt0822.form.placeholder.email': 'Email',
  'rt0822.form.sendButton': 'Enviar',

  'rt0822.form.success.title': 'Sucesso!',
  'rt0822.form.success.text':
    'Obrigado por sua inscrição! Em\xa0breve um\xa0dos nossos especialistas irá entrar em\xa0contato com você.',
  'rt0822.form.success.button': 'Ok, fechar',

  'courses.form.success.title': 'Sucesso!',
  'courses.form.success.text':
    'Obrigado por sua inscrição! Em\xa0breve um\xa0dos nossos especialistas irá entrar em\xa0contato com você.',
  'courses.form.success.button': 'Ok, fechar',

  'rt0822.blog.button': 'Acesse o blog',

  // EVENTOS
  'eventos.register.title': 'Inscreva-se',

  'eventos.register.type.title.online': 'Evento online',
  'eventos.register.type.title.online.mobile': 'Online',
  'eventos.register.type.title.offline': 'Offline event',
  'eventos.register.type.places': '{now} / {total} Lugares livres disponíveis',

  'eventos.register.form.name': 'Nome',
  'eventos.register.form.email': 'Email',
  'eventos.register.form.submit': 'Inscreva-se Agora',
  'eventos.register.gift.text':
    'Junte-se ao evento agora e receba um tutorial gratuito "como destacar seu perfil no Linkedin"',

  'eventos.register.success.title': 'Sucesso!',
  'eventos.register.success.text':
    'Agradecemos sua inscrição! O link de acesso chegará no seu e-mail.',
  'eventos.register.success.button': 'Ok, fechar',

  'eventos.speakers.titleMultiple': 'Palestrantes',
  'eventos.speakers.title': 'Palestrante',
  'eventos.speakers.more': 'Ler mais',
  'eventos.speakers.less': 'Ler menos',

  'eventos.recomendations.title': 'Eventos interessantes',
  'eventos.recomendations.more': 'Mostrar mais',

  'eventos.schedule.title': 'Agenda do Evento',

  'eventos.topics.day': 'Day',

  'eventos.stream.chat.title': 'Chat',

  'eventos.description.prefix': 'Participe do evento',
  'eventos.type.discussion': 'Dicas de carreira',
  'eventos.type.success_case': 'Cases de sucesso',
  'eventos.type.webinar': 'Webinar',
  'eventos.type.workshop': 'Workshop',
  'eventos.type.marathon': 'Maratona',
  'eventos.type.demonstration': 'Demonstração',
  'eventos.type.event': 'Evento',
  'eventos.type.masterclass': 'Masterclass',
  'eventos.type.immersion': 'Imersão',

  'eventos.breadcrumbs.home': 'Home',
  'eventos.breadcrumbs.eventos': 'Webinars',

  'eventos.banner.members': 'Aproveitaram<br/> o desconto',
  'eventos.banner.discount': 'de desconto',

  'eventos.topic.num': 'DIA',

  'eventos.subscribed.title':
    '🔥 Parabéns! Você se inscreveu para este webinar',
  'eventos.subscribed.subtitle':
    'Não se esqueça de voltar quando o webinar começar, nós notificaremos você por e-mail adicionalmente',
  'eventos.subscribed.timer.title': 'Vamos começar em:',
  'eventos.subscribed.timer.value': 'Em breve',
  'eventos.subscribed.members': 'Participantes',
  'eventos.subscribed.button.default': 'Link para um(a) amigo(a)',
  'eventos.subscribed.button.clicked': 'Link copiado',
  'eventos.subscribed.button.info':
    'Convide um(a) amigo(a), enviando-lhe o link',
  'eventos.register.error.email': 'Digite seu e-mail',
  // EVENTOS END

  // EVENTOS LIST START
  'rt0822.events.head.title': 'Webinars gratuitos e eventos EBAC Online',
  'rt0822.events.head.description':
    'Os Webinars irão ajudá-lo a dominar as habilidades em design, programação e marketing. Inscreva-se nos eventos e assista às transmissões anteriores.',

  'rt0822.multiselect.selected': '{count} selecionada',

  'rt0822.multiselect.directions.selected':
    '{count} área selecionada | {count} áreas selecionadas',
  'rt0822.multiselect.types.selected':
    '{count} tipo selecionado | {count} tipos selecionados',

  'rt0822.events.filters.directions.placeholder': 'Todas as áreas',
  'rt0822.events.filters.types.placeholder': 'Todos os tipos',

  'rt0822.events.filter.mobile.directions.title': 'Todas as áreas',
  'rt0822.events.filter.mobile.types.title': 'Todos os tipos de eventos',

  'rt0822.events.filters.search.placeholder': 'Encontrar evento',
  'rt0822.events.filter.clear': 'Limpar',

  'rt0822.events.more': 'Mostrar mais',

  'rt0822.events.filter.mobileLabel':
    'Filtros | {count} filtro selecionado | {count} filtros selecionados',

  'rt0822.events.filter.mobile.title': 'Filtros',
  'rt0822.events.filter.mobile.select': 'Selecionar',
  'rt0822.events.filter.mobile.apply': 'Inscrever-se',

  'rt0822.events.title': 'Eventos EBAC',
  'rt0822.events.past': 'Ir para eventos anteriores',
  'rt0822.events.upcoming': 'Ir para próximos eventos',
  'rt0822.events.subheading':
    'Se você está indeciso(a) sobre qual profissão escolher, veja um dos nossos workshops ou maratonas gratuitas. Você ficará familiarizado(a) com o campo e fará alguns exercícios práticos, cujos resultados podem ser incluídos no seu portfólio.',
  'rt0822.events.nothingWasFound': 'Essa busca não retornou nada.',

  // EVENTOS LIST END

  //PAYMENT PAGE
  'payment.generating': 'Gerando seu link de pagamento...',
  'payment.privacy': 'Eu li e aceito os termos e condições da',
  'payment.privacy-link':
    'Política de Privacidade e Contrato de Prestação de Serviço',
  'payment.thanks-for-waiting': 'Obrigado por aguardar!',
  'payment.payment-link-is-ready': 'Seu link de pagamento está pronto',
  'payment.final': 'Continuar para a matrícula',
  'payment.success': 'O pagamento foi realizado com sucesso',
  'payment.success-details':
    'Detalhes de compra e acesso foram enviados para o seu email',
  'payment.email': 'Para quaisquer dúvidas, entre em contato com a gente em:',
  'payment.something-wrong': 'Algo deu errado',
  'payment.something-wrong-dsc':
    'O pagamento não foi realizado. Algo pode ter sido preenchido incorretamente ou não há saldo suficiente',
  'payment.try-again': 'Tente novamente',

  'saleNew.todos': 'Todos',
  'saleNew.type.course': 'Cursos',
  'saleNew.type.profession': 'Profissões',
  'saleNew.additional.jobGuarantee': 'Garantia de Emprego',
  'saleNew.additional.bundle': 'Pacote de ofertas',
  'saleNew.card.action.add': 'Adicionar',
  'saleNew.card.action.added': 'Ir para o carrinho',
  'saleNew.card.action.open': 'Mais sobre o curso',

  'saleNew.cards.title.course': 'Cursos',
  'saleNew.cards.title.profession': 'Profissões',
  'saleNew.cards.title.course.todos': 'Todos os cursos',
  'saleNew.cards.title.profession.todos': 'Todas as profissões',
  'saleNew.cards.title.category': 'de',
  'saleNew.cards.title.course.jobGuarantee': 'Cursos com Garantia de Emprego',
  'saleNew.cards.title.profession.jobGuarantee':
    'Profissões com Garantia de Emprego',

  'saleNew.price.plan': 'x de',
  'saleNew.price.value.noPercents': 'sem juros<br/>no cartão',
  'saleNew.card.discount.off': 'OFF',
  'saleNew.filter.empty': 'Hmm...parece que você não encontrou o que precisava',
  'saleNew.tooltip.courses.title': 'Curso',
  'saleNew.tooltip.courses':
    'Programa de curta duração que permitirá a sua entrada numa especialização na área de atuação escolhida aprendendo habilidades específicas do nível básico ao avançado que você irá usar em seu portfólio ou crescimento profissional',
  'saleNew.tooltip.professions.title': 'Profissão',
  'saleNew.tooltip.professions':
    'Programa de longa duração com formação abrangente que proporciona um domínio de todos os aspectos da área de atuação escolhida. Você também poderá construir seu portfólio, criar seu currículo e preparar-se para garantir uma colocação no mercado de trabalho.',
  'saleNew.tooltip.jobGuarantee.title': 'Garantia de Emprego',
  'saleNew.tooltip.jobGuarantee':
    'A EBAC garante emprego após a conclusão de determinados cursos profissionalizantes ou devolvemos o seu dinheiro. Nossa <a href="https://ebaconline.com.br/upload/cms/programa_de_empregabilidade.pdf" target="_blank">Política de Emprego Garantido</a> exige dedicação e será preciso esforço e foco no cumprimento integral de todos os requisitos do programa. Acesse aqui a lista dos cursos com garantia de emprego.',
  'saleNew.tooltip.bundle':
    'Oferta especial: ao comprar um curso da lista abaixo, você receberá um curso extra! Basta procurar a descrição do curso extra sob o título do curso principal.',
  'saleNew.timerPanel.text-1': 'Garanta um',
  'saleNew.timerPanel.text-2': 'desconto exclusivo!',
  'saleNew.timerPanel.btn': 'Obter desconto',

  // EventSale Redisign
  'eventSale.badge': 'Oferta exclusiva',

  'eventSale.message.title': 'Marque sua consulta',
  'eventSale.message.subtitle.1': 'E garanta ',
  'eventSale.message.subtitle.2': 'de desconto',
  'eventSale.message.subtitle.3': 'no curso de',

  'eventSale.message.subtitle.2b': 'de desconto no curso',
  'eventSale.message.subtitle.3b': 'de ',

  'eventSale.message.subtitle.4': 'pagando 12 x ',

  'eventSale.form.title': 'Como podemos te contatar?',
  'eventSale.form.submit': 'Enviar',

  'eventSale.timer.days': 'dias',
  'eventSale.timer.hours': 'horas',
  'eventSale.timer.minutes': 'minutos',
  'eventSale.timer.seconds': 'segundos',

  'eventSale.thanks.title': 'Obrigado!',
  'eventSale.thanks.subtitle':
    'Obrigado pela sua inscrição, em breve um dos nossos especialistas irá entrar em contato com você.',
  'eventSale.thanks.form.title': 'Quer finalizar sua matrícula agora?',
  'eventSale.thanks.form.subtitle':
    'Faça o pagamento online. É simples, rápido e seguro.',
  'eventSale.thanks.form.submit': 'Finalizar matrícula',

  // EventSale Redisign END
  moreButton: 'Mostrar mais',

  // Snackbar
  'snackbar.error.default':
    'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  // Snackbar END
};
